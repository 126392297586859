import styled from 'util/style'

const ImageCredits = styled.div`
  font-size: smaller;
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
`

export default ImageCredits
